// Brand Color Palette
$unleashed-dark-gray: #1E1E1E;
$unleashed-black: #000;
$unleashed-yellow: #EEEE22;
$unleashed-pink: #E0198F;

$ua-pink: #E50695;
$ua-yellow: #E0E621;
$ua-black: #000005;

$snap-light-green: #339933;
$snap-green: #006633;
$snap-dark-green: #333300;
$snap-blue: #3366CC;
$snap-red: #FF3333;
$snap-orange: #FF6633;
$snap-yellow: #FFCC33;
$snap-black: #231F20;

$tlg-light-blue: #3FC0C3;
$tlg-blue: #5261AC;
$tlg-pink: #ED1C8F;
$tlg-green: #C1D82F;
$tlg-gray: #636466;

$pma-yellow: #F8C925;
$pma-green: #17AC4A;
$pma-grey: #232021;

//Product Color Palette
$platinum: #C69214;
$ultimate: #2518B1;
$deluxe: #99519F;
$silver: #ACACAC;
$basic: #38C5F3;
$other: #5A5B5D;
$under5: #78C043;

// Other
$success: #4CCF42;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// Leans toward white text on brand primary colors
$min-contrast-ratio: 3;
