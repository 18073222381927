@import "../variables";

$primary: $snap-green;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $other,
  "success":    $success,
  "info":       #0DCAF0,
  "warning":    #FFC107,
  "danger":     #DC3545,
  "light":      #F8F9FA,
  "dark":       #212529,
  "alt":        $snap-orange
);
